<template>
  <!-- 防爆充电房,英文版 -->
  <div class="around chargingRoom" v-if="elmentList.length">
    <div class="banner_video"  :id="'elm' + elmentList[0].id">
      <img :src="elmentList[0].pcImg" v-if="this.$store.state.pageset.pcshow" />
      <img :src="elmentList[0].phoneImg" v-else />
      <div class="img-con">
        <div class="title">
          <h1>{{ elmentList[0].descList[0].descName }}</h1>
        </div>
      </div>
      <div class="dec" v-animate="'queue-bottom'">
        <div class="dec-par">
          <div class="dec-par-item" v-for="index in 4" :key="index">
            <p class="dec-par-item-title">
              {{ elmentList[0].descList[index].descName }}
            </p>
            <p class="dec-par-item-dec">
              {{ elmentList[0].descList[index + 4].descName }}
            </p>
          </div>
        </div>
        <div class="scroll-wrap">
          <div class="remark">
            The products appearing in the video on this page are for reference only. The appearance, configuration, model, and other details of the products may vary. Please refer to the actual local delivery for details.
          </div>
        </div>
      </div>
    </div>
    <div class="chargingRoom-content">
      <div class="content-one" v-for="index in 2" :key="index"  :id="'elm' + elmentList[index].id">
        <img :src="elmentList[index].pcImg" v-if="$store.state.pageset.pcshow" />
        <img :src="elmentList[index].phoneImg" v-else />
        <div class="dec">
          <div class="dec-left" v-if="index === 1">
            <p class="p2" v-animate="'queue-bottom'">
              {{ elmentList[index].descList[0].descName }}
            </p>
          </div>
          <div class="dec-left" v-else>
            <p class="p1" v-animate="'queue-bottom'">
              {{ elmentList[index].descList[0].descName }}
            </p>
            <p class="p2" v-animate="'queue-bottom'">
              {{ elmentList[index].descList[1].descName }}
            </p>
          </div>
        </div>
      </div>
      <!-- 左右排列参数 -->
      <div class="content-tree" v-if="this.$store.state.pageset.pcshow">
        <div v-for="index in 4" :key="index"  :id="'elm' + elmentList[index + 2].id">
          <div class="fun-item" v-if="index % 2 === 0">
            <div class="fun-left">
              <img :src="elmentList[index + 2].pcImg" />
            </div>
            <div class="fun-right">
              <div class="fun-right-dec" v-animate="'queue-bottom'">
                <p class="p1">
                  {{ elmentList[index + 2].descList[0].descName }}
                </p>
                <p class="p2">
                  {{ elmentList[index + 2].descList[1].descName }}
                </p>
              </div>
            </div>
          </div>
          <div class="fun-item" v-else>
            <div class="fun-left">
              <div class="fun-right-dec" v-animate="'queue-bottom'">
                <p class="p1">
                  {{ elmentList[index + 2].descList[0].descName }}
                </p>
                <p class="p2">
                  {{ elmentList[index + 2].descList[1].descName }}
                </p>
              </div>
            </div>
            <div class="fun-right">
              <img :src="elmentList[index + 2].pcImg" />
            </div>
          </div>
        </div>
      </div>
      <div class="content-tree" v-else>
        <div v-for="index in 4" :key="index">
          <div>
            <img :src="elmentList[index + 2].phoneImg" width="100%" height="100%" />
          </div>
          <div class="fun-dec">
            <div class="fun-right-dec" v-animate="'queue-bottom'">
              <p class="p1">{{ elmentList[index + 2].descList[0].descName }}</p>
              <p class="p2">{{ elmentList[index + 2].descList[1].descName }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div v-else v-loading="true"></div>
</template>
<script>
import chargingRoom_en from "./chargingRoom_en.js";

export default chargingRoom_en;
</script>
<style scoped rel="stylesheet/stylus" lang="scss">
@import "~@/assets/style/common.scss";
@import "../../common.scss";
@import "../chargingRoom.scss";

@media screen and (max-width: 750px) {
  .content-one{
    .dec{
      height: unset !important;
      padding-bottom: 10px !important;
    }
  }
}

</style>
