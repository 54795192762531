
export default {
    data() {
        return {
            // elmentList:
            //     [
            //         {
            //             pcElementId: 144,
            //             phoneElementId: 151,
            //             descList: [
            //                 {
            //                     descName: 'Explosion-proof Charging Room',
            //                     sort: 1
            //                 }, {
            //                     descName: 'Ex d IIB T4 Gb',
            //                     sort: 2
            //                 }, {
            //                     descName: 'IP55',
            //                     sort: 3
            //                 }, {
            //                     descName: this.$store.state.pageset.pcshow ? '2900mm×2000mm×2500mm' : '2.9m×2m×2.5m',
            //                     sort: 4
            //                 }, {
            //                     descName: 'Maximum Level 8 Wind',
            //                     sort: 5
            //                 },
            //                 {
            //                     descName: 'Explosion-proof Grade',
            //                     sort: 6
            //                 }, {
            //                     descName: 'Protection Level',
            //                     sort: 7
            //                 }, {
            //                     descName: 'Dimension (L×W×H)',
            //                     sort: 8
            //                 }, {
            //                     descName: 'Wind Resistance',
            //                     sort: 9
            //                 }],
            //              type: 'image',
            //             sort: 1
            //         },
            //         {
            //             pcElementId: 145,
            //             phoneElementId: 152,
            //             descList: [{
            //                 descName: 'It has the functions of wind and flood prevention, ventilation and moisture-proof, constant temperature, explosion-proof and fire prevention, and it can meet the requirements of all-weather storage and automatic charging. It can effectively improve the service life of charging piles and robots, improve the stability of robot charging, expand the scope of application, and reduce the impact of extreme weather on the charging of inspection robots.',
            //                 sort: 1
            //             },],
            //              type: 'image',
            //             sort: 2
            //         },
            //         {
            //             pcElementId: 146,
            //             phoneElementId:153,
            //             descList: [
            //                 {
            //                     descName: 'Installation Environment',
            //                     sort: 1
            //                 }, {
            //                     descName: 'he requirement for the installation of the charging room is to be flat, not in low-lying areas, not prone to water accumulation. In areas with strong winds, reinforcement and connection treatment with the ground should be considered. There should be no corrosive substances that cause destructive insulation in the environment, and there should be no significant impact or vibration in the installation site.',
            //                     sort: 2
            //                 }],
            //              type: 'image',
            //             sort: 3
            //         },
            //         {
            //             pcElementId: 147,
            //             phoneElementId: 154,
            //             descList: [
            //                 {
            //                     descName: 'Electric Door Control',
            //                     sort: 1
            //                 }, {
            //                     descName: 'The charging room door adopts an electric roller shutter door, which will automatically open when the robot starts the inspection task or returns from the inspection task. When the robot remains charged, the roller shutter door automatically closes. Roller shutter doors can also be manually controlled locally',
            //                     sort: 2
            //                 }],
            //              type: 'image',
            //             sort: 4
            //         },
            //         {
            //             pcElementId: 148,
            //             phoneElementId: 155,
            //             descList: [
            //                 {
            //                     descName: 'Remote Control',
            //                     sort: 1
            //                 }, {
            //                     descName: 'The equipment of the charging room can be checked and controlled remotely on the inspection platform to know the operation of the charging room',
            //                     sort: 2
            //                 }],
            //              type: 'image',
            //             sort: 5
            //         },
            //         {
            //             pcElementId: 149,
            //             phoneElementId:156,
            //             descList: [
            //                 {
            //                     descName: 'Heating / Cooling System (optional)',
            //                     sort: 1
            //                 }, {
            //                     descName: 'Heating / cooling facilities are installed in the charging room, which can be manually or remotely switched.',
            //                     sort: 2
            //                 }],
            //              type: 'image',
            //             sort: 6
            //         },
            //         {
            //             pcElementId: 150,
            //             phoneElementId:157,
            //             descList: [
            //                 {
            //                     descName: 'Micro Meteorological Station (optional)',
            //                     sort: 1
            //                 }, {
            //                     descName: 'Customizable explosion-proof micro meteorological station for robot matching. Micro meteorological stations provide the ability to collect the six most common meteorological environments, including temperature and humidity, air pressure, wind speed, wind direction and rainfall.',
            //                     sort: 2
            //                 }],
            //              type: 'image',
            //             sort: 7
            //         }
            //     ],
                elmentList:[]
        }
    },
    created() {
        //         this.$store.dispatch("pageset/getSourceData",
        //     {
        //         createBy: '',
        //         updateBy: '',
        //         elmentList: this.elmentList,
        //         pageId: this.$route.query.pageId,//页面id
        //         pageName: this.$route.path,
        //         path: this.$route.path,
        //         remark: '',
        //     }
        // );
        this.$store.dispatch("pageset/getbannerIndex", 0);
        document.documentElement.style.setProperty('--var-primary-color', '#fff');
        // 获取当前页面的配置信息
        this.$store.dispatch('pageset/getPageInfo', this.$route.query.pageId).then(() => {
            this.$nextTick(() => {
                this.elmentList = this.$store.state.pageset.sourceData.elmentList;
            });
        });
    },
    methods: {
        retention() {
            this.$router.push('/en/reservation');
        },
        openPdf() {
             this.$router.push('/pdfView')
        },

    }
}